<template>
  <div class="page-wrapper">
    <navigation
      v-if="showNavigation"
      @close="this.showNavigation = false"
    ></navigation>

    <div class="toolbar">
      <div class="menu">
        <button @click="toggleNavigation()" class="burger">
          <i class="material-icons">menu</i>
        </button>
      </div>
      <h1 class="title">
        {{ $route.name }}
      </h1>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import navigation from '@/components/Navigation.vue';
import { defineComponent, ref } from 'vue';
import api from '@/api';
import { useLoadingBar } from 'naive-ui';

export default defineComponent({
  setup() {
    api.loadingBar = useLoadingBar();
    return {
      showNavigation: ref(false),
      toggleNavigation() {
        this.showNavigation = !this.showNavigation;
      },
    };
  },
  components: {
    navigation,
  },
});
</script>

<style lang="scss" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.toolbar {
  flex: 0 0 auto;
  width: 100%;
  background: var(--accent);
  color: var(--accent-font);
  display: flex;
  height: 44px;
  box-shadow: 0 0 20px var(--shadow);
  align-items: center;
  margin-bottom: 20px;
}

.menu {
  margin: 10px;
  padding-right: 10px;
  border-right: 1px solid var(--primary-font);
}

.burger {
  border: none;
  cursor: pointer;
  color: inherit;
  height: 32px;
  width: 32px;
  padding: 0;
  line-height: 0;
  background: transparent;
}

.title {
  margin: 0;
  display: block;
}

.content {
  position: relative;
  flex: 1 1 auto;
  overflow: auto;
}
</style>
