import { createStore } from 'vuex';

export const store = createStore({
  state() {
    return {
      login: null,
      id: null,
    };
  },
  mutations: {
    SET_USER(state, user) {
      this.state.login = user.login;
      this.state.id = user.id;
    },
    UNSET_USER(state) {
      state.id = null;
      state.loging = null;
    },
  },
});
