<template>
  <div class="dashboard">
    <server-card></server-card>
  </div>
</template>

<script>
import ServerCard from '@/components/ServerCard';
export default {
  name: 'Dashboard',
  components: { ServerCard }
};
</script>

<style scoped lang="scss">
.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
