<template>
  <section class="login">
    <header class="header">Minecraft Manager</header>
    <div class="content">
      <n-spin :show="formDisabled">
        <n-form
          :disabled="formDisabled"
          :model="formValue"
          :rules="formRules"
          ref="formRef"
        >
          <n-form-item path="login" label="Login">
            <n-input
              placeholder="Input Login"
              v-model:value="formValue.login"
            />
          </n-form-item>
          <n-form-item path="password" label="Password">
            <n-input
              placeholder="Input Password"
              type="password"
              v-model:value="formValue.password"
            />
          </n-form-item>
          <div class="controls">
            <n-button
              attr-type="submit"
              type="primary"
              @click="login()"
              :disabled="formDisabled"
            >
              Login
            </n-button>
          </div>
        </n-form>
      </n-spin>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from 'vue';
import api from '@/api';
import router from '@/router';
import { useLoadingBar, useMessage } from 'naive-ui';

export default defineComponent({
  setup() {
    api.loadingBar = useLoadingBar();
    const message = useMessage();

    const formRef = ref(null);
    return {
      formDisabled: ref(false),
      formRef,
      formValue: ref({
        login: '',
        password: '',
      }),
      formRules: {
        login: {
          required: true,
          message: 'Login is required',
          trigger: ['input', 'blur'],
        },
        password: {
          required: true,
          message: 'Password is required',
          trigger: ['input', 'blur'],
        },
      },
      login() {
        formRef.value.validate(async errors => {
          if (!errors) {
            this.formDisabled = true;
            await api
              .post('auth', {
                login: this.formValue.login,
                password: this.formValue.password,
              })
              .then(response => {
                localStorage.setItem('jwt', response.data.jwt);
                router.push('/dashboard');
              })
              .catch(error => {
                switch (error.response.status) {
                  case 401:
                    message.error('Login failed.');
                    break;
                  case 502:
                    message.error('API Service is not running.');
                    break;
                  default:
                    message.error('Error: ' + error.response.status);
                }
                this.formDisabled = false;
              });
          }
        });
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2em;
  padding: 20px;
  text-align: center;
  color: #fff;
  background: url(~@/assets/logo.png) no-repeat left center;
  background-size: cover;
  text-shadow: 0 0 5px #000;
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px var(--shadow);
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.content {
  padding: 10px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
