import axios from 'axios';
import { store } from '@/stores/store';

const api = axios.create({
  baseURL: '/api/v1',
});

api.loadingBar = {
  start() {},
  finish() {},
  error() {},
};

api.interceptors.request.use(config => {
  api.loadingBar.start();
  const token = localStorage.getItem('jwt');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  response => {
    api.loadingBar.finish();
    return Promise.resolve(response);
  },
  error => {
    api.loadingBar.error();
    if (error.response.status === 401) {
      localStorage.removeItem('jwt');
      store.commit('UNSET_USER');
    }
    return Promise.reject(error);
  },
);

export default api;
