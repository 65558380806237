<template>
  <n-config-provider
    :theme="theme"
    class="wrapper"
    :theme-overrides="themeOverrides"
  >
    <n-loading-bar-provider>
      <n-message-provider>
        <router-view />
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script>
import { defineComponent } from 'vue';
import { darkTheme } from 'naive-ui';

export default defineComponent({
  setup() {
    return {
      theme: darkTheme,
      themeOverrides: {
        common: {
          borderRadius: '0px',
          borderRadiusSmall: '0px',
          fontFamily: "'Roboto', sans-serif",
          primaryColor: '#34aa2f',
          primaryColorHover: '#3ecb38',
          primaryColorPressed: '#247921',
          primaryColorSuppl: '#1e641b',
          successColor: '#34aa2f',
          successColorHover: '#3ecb38',
          successColorPressed: '#247921',
          successColorSuppl: '#1e641b',
          errorColor: '#aa2f2f',
          errorColorHover: '#cb3838',
          errorColorPressed: '#792121',
          errorColorSuppl: '#641b1b',
        },
      },
    };
  },
});
</script>

<style lang="scss">
body,
html,
#app {
  height: 100%;
}

body {
  background: var(--page-background);
  color: var(--font);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.5em;
  font-weight: 500;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

a:visited,
a:focus,
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

:root {
  --page-background: #212121;
  --shadow: rgba(0, 0, 0, 0.38);
  --overlay: rgba(0, 0, 0, 0.32);
  --primary: #34aa2f;
  --primary-font: #fff;
  --accent: #313131;
  --font: #cecece;
  --accent-font: #fff;
  --border: #d3dae6;
  --accent-hover: #4a4a4a;
  --accent-active: #212121;
  --alternate-background: #313131;
  --alternate-font: #cecece;
}

.material-icons {
  vertical-align: middle;

  &--small {
    font-size: 18px;
  }
}
</style>

<style scoped>
.wrapper {
  height: 100%;
}
</style>
