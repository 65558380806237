import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Page from '../views/Page.vue';
import Login from '../views/Login.vue';
import api from '@/api';
import { store } from '@/stores/store';

const routes = [
  {
    path: '/',
    name: 'Page',
    component: Page,
    redirect: '/dashboard',
    beforeEnter: async (to, from, next) => {
      if (localStorage.getItem('jwt')) {
        await api
          .get('auth')
          .then((response) => {
            store.commit('SET_USER', response.data);
            next();
          })
          .catch(() => {
            next('login');
          });
      } else {
        next('login');
      }
    },
    children: [
      {
        path: 'versions',
        name: 'Versions',
        component: () =>
          import(/* webpackChunkName: "versions" */ '../views/Versions.vue'),
      },
      {
        path: 'users',
        name: 'Users',
        component: () =>
          import(/* webpackChunkName: "users" */ '../views/Users.vue'),
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'error/:status',
        name: 'Error',
        component: () =>
          import(/* webpackChunkName: "error" */ '../views/Error.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (localStorage.getItem('jwt')) {
        await api
          .get('auth')
          .then(() => {
            next('dashboard');
          })
          .catch(() => {
            next();
          });
      } else {
        next();
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: {
      render() {},
    },
    beforeEnter(to, from, next) {
      localStorage.removeItem('jwt');
      store.commit('UNSET_USER');
      next('login');
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/login',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
