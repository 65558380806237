<template>
  <n-spin :show="loading" class="server-card">
    <n-card title="Server">
      <n-select
        v-model:value="selectedServer"
        filterable
        placeholder="Select a version"
        :options="servers"
      />
      <template #action>
        <div class="actions">
          <n-button
            :disabled="!!!selectedServer"
            class="button"
            @click="startServer"
            v-if="!running"
          >
            <template #icon>
              <i class="material-icons material-icons--small">play_arrow</i>
            </template>
            Start
          </n-button>
          <n-button
            class="button"
            type="error"
            ghost
            @click="stopServer"
            v-if="running"
          >
            <template #icon>
              <i class="material-icons material-icons--small">stop</i>
            </template>
            Stop
          </n-button>
        </div>
      </template>
    </n-card>
  </n-spin>
</template>

<script>
import { defineComponent, ref } from 'vue';
import api from '@/api';
import { useMessage } from 'naive-ui';
export default defineComponent({
  setup() {
    return {
      message: useMessage(),
      selectedServer: ref(null),
    };
  },
  created() {
    this.getServer();
  },
  data() {
    return {
      loading: true,
      running: null,
      servers: [],
    };
  },
  methods: {
    startServer() {
      if (!this.selectedServer) {
        this.message.error('Select a version.');
        return;
      }
      this.loading = true;
      api
        .post('server/start', { version: this.selectedServer })
        .then(() => {
          this.running = true;
        })
        .catch(error => {
          switch (error.response.status) {
            case 400:
              this.message.error('No version submitted.');
              break;
            case 409:
              this.message.error('Server is already running.');
              this.running = true;
              break;
            default:
              this.message.error(`Server Start: ${error.response.status}`);
          }
        })
        .finally(() => (this.loading = false));
    },
    stopServer() {
      this.loading = true;
      api
        .post('server/stop')
        .then(() => {
          this.running = false;
        })
        .catch(error => {
          switch (error.response.status) {
            case 404:
              this.message.error('Server is not running.');
              this.running = false;
              break;
            default:
              this.message.error(`Server Stop: ${error.response.status}`);
          }
        })
        .finally(() => (this.loading = false));
    },
    async getServer() {
      api
        .get('server')
        .then(result => {
          this.selectedServer = result.data.running || null;
          this.running = !!result.data.running;
          this.servers = result.data.installed.map(e => {
            return { label: e, value: e };
          });
        })
        .catch(error => {
          this.message.error(`Server: ${error?.response?.status}`);
        })
        .finally(() => (this.loading = false));
    },
  },
});
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  justify-content: flex-end;
}
.button {
  margin-left: 10px;
}
</style>
