<template>
  <transition
    name="fade"
    @after-enter="showMenu = true"
    @after-leave="$emit('close')"
  >
    <div class="navigation" @click="showMenu = false" v-if="showOverlay">
      <transition name="slide" @after-leave="showOverlay = false">
        <div class="drawer" v-if="showMenu" @click.stop>
          <div class="title">Minecraft Manager</div>
          <router-link class="link" to="/dashboard" @click="close">
            <i class="material-icons">dashboard</i> Dashboard
          </router-link>
          <router-link class="link" to="/versions" @click="close">
            <i class="material-icons">storage</i> Versions
          </router-link>
          <router-link class="link" to="/users" @click="close">
            <i class="material-icons">group</i> Users
          </router-link>
          <div class="bottom">
            <router-link class="link" to="/logout" @click="close">
              <i class="material-icons">logout</i> Logout
            </router-link>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      showOverlay: false,
    };
  },
  mounted() {
    this.showOverlay = true;
  },
  methods: {
    close() {
      this.showMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: var(--overlay);
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2em;
  padding: 10px;
  text-align: center;
  color: #fff;
  background: url(~@/assets/logo.png) no-repeat left center;
  background-size: cover;
  box-shadow: 0 0 10px var(--shadow);
  text-shadow: 0 0 5px #000;
}

.drawer {
  width: 200px;
  display: flex;
  flex-direction: column;
  max-width: 75%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  background: var(--accent);
  color: var(--accent-font);
  position: absolute;
  z-index: 2;
  box-shadow: 0 0 20px var(--shadow);
}

.bottom {
  margin-top: auto;
  border-top: 1px solid var(--border);
}

.link {
  position: relative;
  display: block;
  color: var(--accent-font);
  padding: 10px 10px 10px 40px;
  line-height: 24px;
  background: transparent;
  transition: background-color 100ms ease;

  .material-icons {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background: var(--accent-hover);
  }

  &.router-link-active {
    background: var(--primary);
    color: var(--primary-font);
  }
}

.fade {
  &-enter-active,
  &-leave-to {
    opacity: 0;
  }

  &-leave,
  &-enter-to {
    opacity: 1;
  }

  &-enter-active,
  &-leave-active {
    transition: 150ms opacity ease-in-out;
  }
}

.slide {
  &-enter-active,
  &-leave-to {
    transform: translateX(-200px);
  }

  &-leave,
  &-enter-to {
    transform: translateX(0);
  }

  &-enter-active,
  &-leave-active {
    transition: 150ms transform ease-in-out;
  }
}
</style>
