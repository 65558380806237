import 'normalize.css/normalize.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import moment from 'moment';
import {
  create,
  NConfigProvider,
  NButton,
  NCheckbox,
  NInput,
  NForm,
  NFormItem,
  NSpin,
  NMessageProvider,
  NResult,
  NLoadingBarProvider,
  NCard,
  NPopconfirm,
  NSelect,
} from 'naive-ui';
import { store } from './stores/store';

const naive = create({
  components: [
    NConfigProvider,
    NButton,
    NInput,
    NForm,
    NCheckbox,
    NFormItem,
    NSpin,
    NMessageProvider,
    NResult,
    NLoadingBarProvider,
    NCard,
    NPopconfirm,
    NSelect,
  ],
});

moment.locale('de');


createApp(App)
  .use(router)
  .use(naive)
  .use(store)
  .mount('#app');
